<template>
    <div class="programme">
        <div class="programme_screen">
            <el-form :inline="true" :model="screenForm" class="demo-form-inline">
                <el-form-item label="方案名称：">
                    <el-input v-model="screenForm.name" placeholder="请输入方案名称"></el-input>
                </el-form-item>
                <el-form-item label="年级：">
                    <el-select v-model="screenForm.grade" placeholder="请选择年级">
                        <el-option 
                            v-for="item in gradeList" 
                            :key="`${item.gradeCode}grade`" 
                            :label="item.gradeName" 
                            :value="item.gradeCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="场景：">
                    <el-select v-model="screenForm.scene" placeholder="请选择场景">
                        <el-option 
                            v-for="item in sceneList" 
                            :key="`${item.code}scene`" 
                            :label="item.name" 
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="screenForm.status" placeholder="请选择状态">
                        <el-option 
                            v-for="item in statusList" 
                            :key="`${item.code}status`" 
                            :label="item.name" 
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型：">
                    <el-select v-model="screenForm.subType" placeholder="请选择状态">
                        <el-option 
                            v-for="item in subTypeList" 
                            :key="`${item.code}subType`" 
                            :label="item.label" 
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期：">
                    <el-date-picker
                        v-model="datePicker"
                        @change="datePickerChange"
                        type="daterange"
                        align="right"
                        clearable
                        unlink-panels
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="timestamp">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="screenHandler">查询</el-button>
                    
                    <el-button type="primary" @click="createProgramme">创建</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="programme_table">
            <el-table
                :data="programmeList"
                height="100%"
                border
                style="width: 100%">
                <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="name"
                    show-overflow-tooltip
                    align="center"
                    label="方案名称">
                </el-table-column>
                <el-table-column
                    prop="grade"
                    align="center"
                    show-overflow-tooltip
                    width="80"
                    label="年级">
                    <template slot-scope="scope">
                        <span>{{gradesName(scope.row.grade)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="scene"
                    align="center"
                    show-overflow-tooltip
                    width="60"
                    label="场景">
                    <template slot-scope="scope">
                        <span>{{sceneName(scope.row.scene)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    align="center"
                    show-overflow-tooltip
                    width="80"
                    label="状态">
                    <template slot-scope="scope">
                        <span>{{statusName(scope.row.status)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="scene"
                    align="center"
                    show-overflow-tooltip
                    width="100"
                    label="类型">
                    <template slot-scope="scope">
                        <span>{{subTypeName(scope.row.subType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createByName"
                    align="center"
                    show-overflow-tooltip
                    width="160"
                    label="创建人">
                    <template slot-scope="scope">
                        <span>{{scope.row.createByName || '--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTimeStr"
                    align="center"
                    show-overflow-tooltip
                    width="160"
                    label="创建时间">
                </el-table-column>
                <el-table-column
                    prop="updateByName"
                    align="center"
                    show-overflow-tooltip
                    width="160"
                    label="修改人">
                    <template slot-scope="scope">
                        <span>{{scope.row.updateByName || '--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="updateTimeStr"
                    align="center"
                    show-overflow-tooltip
                    width="160"
                    label="修改时间">
                </el-table-column>
                
                <el-table-column
                    class-name="programme_table_td"
                    align="center"
                    width="240"
                    label="操作">
                    <template slot-scope="scope">
                        <div class="programme_table_btn">
                            <span class="table_btn_cell" @click="publishProgramme(scope.row)">{{scope.row.status == 0 ? '发布' : '取消发布'}}</span>
                            <span class="table_btn_cell" @click="copyProgramme(scope.row)">克隆</span>
                            <span class="table_btn_cell" @click="editOrView(scope.row, 2)">查看</span>
                            <span class="table_btn_cell" v-if="scope.row.status == 0" @click="editOrView(scope.row, 1)">编辑</span>
                            <span class="table_btn_cell" v-if="scope.row.status == 0" @click="deleteProgramme(scope.row)">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="programme_pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="screenForm.pageNum"
                :page-sizes="[20, 50, 100, 200]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalPage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import $http from '@/service/index';
import moment from 'moment';
export default {
    name: 'programme',
    data() {
        return {
            gradeList: [], // 学年列表
            sceneList: [], // 场景列表
            statusList: [], // 状态列表
            programmeList: [], // 方案列表
            datePicker: '', // 日期条件
            totalPage: 0, // 总页数
            screenForm: {
                "createTimeEnd": '', // 结束时间
                "createTimeStart": '', // 开始时间
                "grade": null, // 年级
                "name": "", // 方案名称
                "pageNum": 1,
                "pageSize": 20,
                "scene": null, // 场景
                "status": null, // 状态
                "subType": null // 类型
            },
            subTypeList: [// 方案类型列表
                {
                    code: null,
                    label: '全部'
                },
                {
                    code: 1,
                    label: '流程'
                },
                {
                    code: 2,
                    label: '模板'
                },
                {
                    code: 3,
                    label: '流程/模板'
                },
            ],
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const arrPage = vm.$store.state.cachePage.indexOf('programme') === -1 ? vm.$store.state.cachePage.concat('programme') : vm.$store.state.cachePage;
            vm.$store.commit('setCachePage', arrPage);
        });
        next()
    },
    beforeRouteLeave (to, from, next) {
        // if (to.path.indexOf('programme') > -1) {
        //     const arrPage = this.$store.state.cachePage;
        //     arrPage.splice(arrPage.findIndex(ele => ele === 'programme'), 1)
            
        //     this.$store.commit('setCachePage', arrPage)
        // }
        next()
    },
    created() {

        document.onkeyup = e => {
            if (e.keyCode == 13 || e.key == 'Enter') {
                
                this.screenHandler()
            }
        }

        // 获取年级
        this.getGrades();
        // 获取筛选码表
        this.getEnum();
        // 获取列表数据
        this.getProgramme();

    },
    activated() {
        // 获取列表数据
        this.getProgramme();
    },
    computed: {
        //年级名称
        gradesName() {
            return function(val) {
                return this.gradeList.find(item => item.gradeCode == val)?.gradeName ?? '--'
            }
        },
        //场景名称
        sceneName() {
            return function(val) {
                return this.sceneList.find(item => item.code == val)?.name ?? '--'
            }
        },
        
        //状态名称
        statusName() {
            return function(val) {
            
                return this.statusList.find(item => item.code == val)?.name ?? '--'
            }
        },

        // 类型名称
        subTypeName() {
            return function(val) {
                return this.subTypeList.find(item => item.code == val)?.label ?? '--'
            }
        },
        
    },
    mounted() {},
    methods: {
        /**
         * 克隆方案
         * @param {Object} row 操作列的数据
         */
        async copyProgramme(row) {
            const params = {
                data: {
                    id: row.id
                },
                config: {
                    showLoading: true,
                    text: '创建中...',
                }
            };
            const res = await $http.copyProgramme(params);

            if(res.code == '000000') {
                this.$message.success('克隆成功');
                this.screenForm.pageNum = 1;
                this.getProgramme();
            }
        },

        /**
         * 发布方案
         * @param {Object} row 操作列的数据
         */
        async publishProgramme(row) {
            
            const params = {
                data: {
                    id: row.id,
                    published: row.status == '0' ? '1' : '0'
                },
                config: {
                    showLoading: true,
                    text: '发布中...',
                }
            };
            const res = await $http.publishProgramme(params);

            if(res.code == '000000') {
                // this.screenForm.pageNum = 1;
                // this.getProgramme();
                this.$message.success(row.status == '0' ? '发布成功' : '取消发布成功');
                const num = this.programmeList.findIndex(item => item.id == row.id);
                
                this.$set(this.programmeList[num], 'status', params.data.published)
            }
        },
        /**
         * 删除方案
         * @param {Object} row 操作列的数据
         */
         async deleteProgramme(row) {
            
            const params = {
                data: {
                    id: row.id
                },
                config: {
                    showLoading: true,
                    text: '删除中...',
                }
            };
            const res = await $http.deleteProgramme(params);

            if(res.code == '000000') {
                // this.screenForm.pageNum = 1;
                
                this.$message.success('删除成功');
                this.getProgramme();
            }
        },
        
        /**
         * 编辑OR查看方案
         * @param {Object} row 操作列的数据
         * @param {Number | String} val 1 == 编辑；2 == 查看
         */
        editOrView(row, val) {
            this.$router.push({
                path: '/createProgramme',
                query: {
                    edit: val,
                    id: row.id
                }
            })
        },

        /**
         * 进入创建方案页面
         */
         createProgramme() {
            this.$router.push({
                path: '/createProgramme'
            })
        },
        /**
         * 获取学年数据
         */
        async getGrades() {
            const res = await $http.getGrades();
            if (res.code == '000000') {
                this.gradeList = res?.grades ?? [];
                this.gradeList.unshift({
                    gradeCode: null,
                    gradeName: '全部'
                })
            }
        },
        /**
         * 获取筛选码表
         */
        async getEnum() {
            const res = await $http.getEnum();
            if (res.code == '000000') {
                this.sceneList = res?.result?.sceneEnum ?? [];
                this.sceneList.unshift({
                    code: null,
                    name: '全部'
                })
                this.statusList = res?.result?.planStatusEnum ?? [];
                this.statusList.unshift({
                    code: null,
                    name: '全部'
                })
            }
        },

        /**
         * 日期改变
         */
        datePickerChange(e) {
            if (!e) {
                this.screenForm.createTimeStart = '';
                this.screenForm.createTimeEnd = '';
                return
            }
            this.screenForm.createTimeStart = e[0];
            this.screenForm.createTimeEnd = e[1];
        },
        /**
         * 点击筛选
         */
        screenHandler() {
            this.screenForm.pageNum = 1;
            this.getProgramme();
        },

        /**
         * 获取方案列表数据
         */
        async getProgramme() {
            let temp = JSON.parse(JSON.stringify(this.screenForm));
            for(let key in temp) {
                !temp[key] && temp[key] !== 0 && (temp[key] = null)
            }
            const params = {
                data: temp,
                config: {
                    showLoading: true,
                    text: '数据加载中...'
                }
            }
            const res = await $http.getProgramme(params);
            if (res.code == '000000') {
                this.totalPage = res?.result?.totalRows ?? 0;
                this.programmeList = res?.result?.result ?? [];
                this.programmeList.forEach(item => {
                    item.updateTimeStr = moment(item.updateTime).format('YYYY-MM-DD HH:mm:ss')
                    item.createTimeStr = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                })
            }
        },

        /**
         * 调整每页条数
         */
        handleSizeChange(val) {

            this.screenForm.pageSize = val;
            this.screenForm.pageNum = 1;
            this.getProgramme();
        },
        // 换页
        handleCurrentChange (val) {
            this.screenForm.pageNum = val;
            this.getProgramme();
        },
    }
}
</script>

<style lang="less" scoped>
.programme {
    height: 100%;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .programme_screen {
        /deep/ .el-form {
            .el-form-item {
                margin-bottom: 0;
                margin-top: 20px;
                .el-select {
                    width: 160px;
                }
            }
        }
    }
    .programme_table {
        padding: 20px;
        flex: 1;
        height: 0;
        /deep/ .programme_table_td {
            padding: 0;
            .programme_table_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                .table_btn_cell{
                    margin-right: 10px;
                    cursor: pointer;
                    color: #778eff;
                    border-bottom: 1px solid #778eff;
                    // text-decoration: underline;
                    &:hover {
                        color: #2447f5;
                        border-bottom-color: #2447f5;
                    }
                }
            }
                
        }
    }
    .programme_pagination {
        text-align: end;
    }
        
}
</style>